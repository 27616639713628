import React from 'react'

import Layout from '../components/layout'
import PrivacyOne from '../components/strategy/PrivacyOne'


const Work = props => (
  <Layout lang="en" title="Privacy and Data Protection Policy" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <PrivacyOne lang="en" />
  </Layout>
)

export default Work
